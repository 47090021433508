export default {
  image({ href, title, text }) {
    if (href === null) return escape(text);

    const cleanHref = `/docs/${href}`;

    let out = `<img src="${cleanHref}" alt="${text}"`;
    if (title) out += ` title="${escape(title)}"`;
    out += '>';
    return out;
  },
  link({ href, title, tokens }) {
    const text = this.parser.parseInline(tokens);
    if (href === null) return text;

    let cleanHref = href;
    let target;
    if (href.startsWith('http')) {
      target = '_blank';
    } else if (!href.startsWith('#')) {
      cleanHref = href.endsWith('.md') ? href.slice(0, -3) : href;
    }

    let out = `<a href="${cleanHref}"`;
    if (target) out += ` target="${target}"`;
    if (title) out += ` title="${escape(title)}"`;
    out += `>${text}</a>`;
    return out;
  },
};
