<script setup>
import { computed } from 'vue';
import TableWithBusyState from '~/common/components/TableWithBusyState.vue';
import ContributorLevelBadge from '~/common/components/ContributorLevelBadge.vue';
import { numberProps } from '~/common/FieldFormats';
import UserWithGitLabProfileLink from '~/common/components/UserWithGitLabProfileLink.vue';
import PointsDocumentationTooltip from '~/common/components/PointsDocumentationTooltip.vue';

const props = defineProps({
  contributors: {
    type: Array,
    default: () => [],
  },
  totals: {
    type: Object,
    default: null,
  },
  busy: {
    type: Boolean,
    default: true,
  },
  filterData: {
    type: Object,
    default: () => { return {}; },
  },
});

const fields = [
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'contributor_level',
    label: 'Level',
    class: 'text-center',
    tdClass: 'p-0',
  },
  {
    key: 'opened_merge_requests',
    label: 'Open',
    ...numberProps,
  },
  {
    key: 'merged_commits',
    label: 'Commit',
    ...numberProps,
  },
  {
    key: 'merged_merge_requests',
    label: 'Merge',
    ...numberProps,
  },
  {
    key: 'merged_with_issues',
    label: 'Link',
    ...numberProps,
  },
  {
    key: 'opened_issues',
    label: 'Issue',
    ...numberProps,
  },
  {
    key: 'added_notes',
    label: 'Note',
    ...numberProps,
  },
  {
    key: 'bonus_points',
    label: 'Bonus',
    ...numberProps,
  },
  {
    key: 'score',
    label: 'Score',
    ...numberProps,
  },
];

const items = computed(() => {
  const contributors = props.contributors.map((item) => {
    const contributor = { ...item };
    if (item.current_user) contributor._rowVariant = 'row-me';

    return contributor;
  });

  if (!props.totals) return contributors;

  const totalsRow = [{
    username: 'table-total',
    level: null,
    ...props.totals,
  }];
  return totalsRow.concat(contributors);
});
</script>

<template>
  <TableWithBusyState
    :fields="fields"
    :items="items"
    :busy="busy"
    show-empty
    hover
    :class="totals ? 'with-totals' : ''"
  >
    <template #head(score)="data">
      <div class="d-inline-flex">
        {{ data.field.label }}
        <PointsDocumentationTooltip />
      </div>
    </template>
    <template #cell(username)="{ value }">
      <span v-if="value === 'table-total'">Total</span>
      <UserWithGitLabProfileLink
        v-else
        :username="value"
        :filter-data="filterData"
      />
    </template>
    <template #cell(contributor_level)="{ value }">
      <ContributorLevelBadge
        :contributor-level="value"
      />
    </template>
  </TableWithBusyState>
</template>
