export const BONUS_POINT_ACTIVITY_TYPES = {
  support: 'Community support',
  content: 'Content creation',
  event: 'Event activity',
  social: 'Social engagement',
  other: 'Other',
};

export const DEFAULT_TITLE = 'GitLab Contributor Platform';

export const REWARD_REASONS = {
  first_contribution: 'First contribution',
  hackathon: 'Hackathon',
  notable_contributor: 'Notable contributor (MVP)',
  level_up: 'Level up',
  other: 'Other',
};

export const LEVEL_POINTS = {
  4: 7_500,
  3: 2_500,
  2: 500,
  1: 25,
  0: 0,
};
