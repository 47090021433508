<script setup>
import { inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import { BContainer } from 'bootstrap-vue-next';
import NavigationMenu from '~/common/components/NavigationMenu.vue';
import FooterNote from '~/common/components/FooterNote.vue';
import NotFoundPage from '~/pages/NotFoundPage.vue';
import { DEFAULT_TITLE } from '~/common/Constants';

const footerNoteData = ref({ customText: null, learnMoreHref: null });
const notFound = ref(false);

const vueData = inject('vueData');

const refreshFooterNote = (routeMeta) => {
  const { customText, learnMoreHref } = routeMeta.footerNote || { customText: null, learnMoreHref: null };
  footerNoteData.value = { customText, learnMoreHref };
};

const router = useRouter();
router.beforeEach((to) => {
  if (to.name == null) {
    notFound.value = true;
    document.title = `Page not found - ${DEFAULT_TITLE}`;
    return;
  }

  notFound.value = false;
  const loggedIn = vueData?.userId != null;
  const admin = vueData?.userAdmin;

  const { requireAdmin, requireLogin, title } = to.meta;

  if (!loggedIn && (requireAdmin || requireLogin)) {
    window.location.href = `/login?return=${to.fullPath}`;
    return false;
  } else if (requireAdmin && !admin) {
    return '/';
  }

  document.title = title ? `${title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE;
  refreshFooterNote(to.meta);
});
</script>

<template>
  <NavigationMenu />
  <BContainer class="pt-2">
    <NotFoundPage v-if="notFound" />
    <router-view v-else />
    <hr>
    <FooterNote
      :custom-text="footerNoteData.customText"
      :learn-more-href="footerNoteData.learnMoreHref"
    />
    <p>
      <a
        href="https://gitlab.com/gitlab-org/developer-relations/contributor-success/contributors-gitlab-com"
        target="_blank"
      >View source</a>
      ||
      <!-- OneTrust Cookies Settings button start -->
      <a
        class="ot-sdk-show-settings"
        href="#"
      >
        Cookie settings
      </a>
      <!-- OneTrust Cookies Settings button end -->
    </p>
  </BContainer>
</template>
