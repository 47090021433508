<script setup>
import { ref } from 'vue';
import { BTable, BSpinner, BLink } from 'bootstrap-vue-next';
import FilterCard from '~/common/components/FilterCard.vue';
import { buildUrl } from '~/common/UrlUtils';
import { numberProps } from '~/common/FieldFormats';
import PointsDocumentationTooltip from '~/common/components/PointsDocumentationTooltip.vue';

const organizations = ref([]);
const busy = ref(true);
const filterData = ref({
  fromDate: '',
  toDate: '',
  search: '',
});

const fields = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'contributors_count',
    label: 'Contributors',
    ...numberProps,
  },
  {
    key: 'score',
    label: 'Score',
    ...numberProps,
  },
];

const fetchData = async () => {
  busy.value = true;
  const query = {
    from_date: filterData.value.fromDate,
    to_date: filterData.value.toDate,
    search: filterData.value.search,
  };
  const url = buildUrl('/api/v1/organizations', { query });

  const response = await fetch(url);
  organizations.value = await response.json();
  busy.value = false;
};
</script>

<template>
  <FilterCard
    v-model="filterData"
    show-search
    @filter="fetchData"
  />

  <BTable
    :fields="fields"
    :items="organizations"
    :busy="busy"
    responsive="md"
    show-empty
  >
    <template #head(score)="{ field: { label } }">
      <div class="d-inline-flex">
        {{ label }}
        <PointsDocumentationTooltip />
      </div>
    </template>
    <template #cell(rank_position)="{ item, value }">
      <BLink
        :to="{
          name: 'organization',
          params: { organizationName: item.name },
          query: { fromDate: filterData.fromDate, toDate: filterData.toDate }
        }"
      >
        {{ value }}
      </BLink>
    </template>
    <template #cell(name)="{ value }">
      <BLink
        :to="{
          name: 'organization',
          params: { organizationName: value },
          query: { fromDate: filterData.fromDate, toDate: filterData.toDate }
        }"
      >
        {{ value }}
      </BLink>
    </template>
    <template #table-busy>
      <BSpinner class="table-busy-spinner" />
    </template>
  </BTable>
</template>
