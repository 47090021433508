<script setup>
import { inject, computed } from 'vue';
import { BCol, BRow } from 'bootstrap-vue-next';
import ActionCard from './home-page/ActionCard.vue';
import LevelProgress from '~/common/components/LevelProgress.vue';
import LoginSvg from '~/../assets/images/empty-user-md.svg';
import ConnectCommunitySvg from '~/../assets/images/chat-sm.svg';
import JoinEventSvg from '~/../assets/images/empty-schedule-md.svg';
import StartOnboardingSvg from '~/../assets/images/empty-todos-md.svg';
import ReadGuideSvg from '~/../assets/images/empty-wiki-md.svg';
import AiPoweredSvg from '~/../assets/images/tanuki-ai-md.svg';

const vueData = inject('vueData');
const signedIn = computed(() => typeof (vueData.username) === 'string');
</script>

<template>
  <h1>Contribute to GitLab</h1>
  <p>
    Join 4,000+ contributors in GitLab's open source community and co-create the platform millions of people use to deliver software faster.
  </p>
  <BRow
    v-if="signedIn"
    class="my-3"
  >
    <BCol>
      <LevelProgress />
    </BCol>
  </BRow>
  <BRow>
    <BCol
      v-if="!signedIn"
      cols="12"
      md="6"
      class="mb-3"
    >
      <ActionCard
        title="Login"
        action-link="/login"
        action-name="Login"
        button-variant="primary"
        :logo-path="LoginSvg"
        :target-blank="false"
      >
        Please login to help us tailor your experience.
      </ActionCard>
    </BCol>
    <BCol
      cols="12"
      md="6"
      class="mb-3"
    >
      <ActionCard
        title="Start onboarding"
        action-link="https://gitlab.com/groups/gitlab-community/community-members/-/group_members/request_access"
        action-name="Start onboarding"
        :button-variant="signedIn ? 'primary' : 'outline-primary'"
        :logo-path="StartOnboardingSvg"
      >
        Start onboarding to contribute to GitLab and get access to GitLab Duo AI.
      </ActionCard>
    </BCol>
    <BCol
      cols="12"
      md="6"
      class="mb-3"
    >
      <ActionCard
        title="AI powered"
        action-link="https://docs.gitlab.com/ee/user/gitlab_duo/"
        action-name="Unlock AI"
        :logo-path="AiPoweredSvg"
      >
        GitLab Duo is included for all contributors to help boost your efficiency and effectiveness.
        Code Suggestions, Chat, Root Cause Analysis and more!
      </ActionCard>
    </BCol>
    <BCol
      v-if="!vueData.hasDiscordMessages"
      cols="12"
      md="6"
      class="mb-3"
    >
      <ActionCard
        title="Connect with the community"
        action-link="https://discord.gg/gitlab"
        action-name="Join Discord"
        :logo-path="ConnectCommunitySvg"
      >
        Team members and community members hangout in the <code>#contribute</code> channel on the GitLab Discord server.
      </ActionCard>
    </BCol>
    <BCol
      v-if="!signedIn || vueData.contributorLevel < 2"
      cols="12"
      md="6"
      class="mb-3"
    >
      <ActionCard
        title="Read the guide"
        action-link="https://docs.gitlab.com/ee/development/contributing/"
        action-name="Read the guide"
        :logo-path="ReadGuideSvg"
      >
        Our contribution guide will help you learn the different ways you can contribute,
        choose a development environment, find issues to work on, make changes, and open a merge request.
      </ActionCard>
    </BCol>
    <BCol
      cols="12"
      md="6"
      class="mb-3"
    >
      <ActionCard
        title="Join an event"
        action-link="https://www.meetup.com/gitlab-virtual-meetups"
        action-name="View events"
        :logo-path="JoinEventSvg"
      >
        Register for an upcoming hackathon, pairing session, or office hour session on Meetup.com.
      </ActionCard>
    </BCol>
  </BRow>
  <br>
  <h1>Everyone can contribute</h1>
  <p>
    It is GitLab's mission to make it so that everyone can contribute.
    <a
      href="https://about.gitlab.com/handbook/values/#diversity-inclusion"
      target="_blank"
    >Diversity, Inclusion, and Belonging</a>
    is one of GitLab's values and we want to create a welcoming community for every contributor.
    GitLab earned a
    <a
      href="https://badging.chaoss.community/project-badging"
      target="_blank"
    >CHAOSS DEI Badge</a>
    for our
    <a
      href="https://gitlab.com/gitlab-org/gitlab/-/blob/ffe0ae7f056a706e5e2a203860b9c7fd334f85a1/DEI.md"
      target="_blank"
    >Diversity, Equity, and Inclusion Project Statement</a>,
    which documents our commitment to DEI within our open source community.
  </p>
  <p>
    Please visit our
    <a
      href="https://about.gitlab.com/community/contribute/code-of-conduct/"
      target="_blank"
    >Code of Conduct page</a>
    to learn more about our pledge and standards for an open and welcoming environment.
  </p>
  <p>
    All contributions to GitLab are subject to the
    <a
      href="https://about.gitlab.com/community/contribute/dco-cla/"
      target="_blank"
    >
      DCO or a CLA</a>.
  </p>
</template>
