import HomePage from '~/pages/HomePage.vue';
import LeaderboardPage from '~/pages/LeaderboardPage.vue';
import ManageMergeRequestPage from '~/pages/ManageMergeRequestPage.vue';
import UserEditPage from '~/pages/UserEditPage.vue';
import UserPage from '~/pages/UserPage.vue';
import RewardsPage from './pages/RewardsPage.vue';
import OrganizationsPage from '~/pages/OrganizationsPage.vue';
import OrganizationPage from '~/pages/OrganizationPage.vue';
import DocumentationPage from '~/pages/DocumentationPage.vue';

const ORGANIZATION_FOOTER = {
  customText: 'This list is generated using the organizations contributors display publicly in their GitLab profile.',
  learnMoreHref: '/docs/platform-data#recognizing-organizations-that-contribute',
};

export default [
  {
    name: 'home',
    path: '/',
    component: HomePage,
  },
  {
    name: 'leaderboard',
    path: '/leaderboard',
    component: LeaderboardPage,
    meta: { title: 'Leaderboard' },
  },
  {
    name: 'manage-merge-request',
    path: '/manage-merge-request/:mergeRequestId',
    component: ManageMergeRequestPage,
    meta: {
      requireLogin: true,
      title: 'Merge request details',
    },
  },
  {
    name: 'rewards',
    path: '/rewards',
    component: RewardsPage,
    meta: {
      title: 'Rewards',
    },
  },
  {
    name: 'user',
    path: '/users/:userHandle',
    component: UserPage,
  },
  {
    name: 'user-edit',
    path: '/users/:userId/edit',
    component: UserEditPage,
    meta: { requireAdmin: true },
  },
  {
    name: 'organizations',
    path: '/organizations',
    component: OrganizationsPage,
    meta: {
      title: 'Organization leaderboard',
      footerNote: ORGANIZATION_FOOTER,
    },
  },
  {
    name: 'organization',
    path: '/organizations/:organizationName',
    component: OrganizationPage,
    meta: {
      footerNote: ORGANIZATION_FOOTER,
    },
  },
  {
    name: 'documentation',
    path: '/docs/:page(.*)',
    component: DocumentationPage,
    props: route => ({ page: route.params.page || 'getting-started' }),
    meta: {
      title: 'Documentation',
    },
  },
];
