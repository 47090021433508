<script setup>
import { computed } from 'vue';

const props = defineProps({
  customText: {
    type: String,
    default: null,
  },
  learnMoreHref: {
    type: String,
    default: null,
  },
});

const defaultText = 'All data is imported from public APIs.';
const text = computed(() => props.customText || defaultText);
</script>
<template>
  <div class="my-2">
    <span>
      {{ text }}
      <template v-if="learnMoreHref">
        <a
          :href="learnMoreHref"
        >Learn more</a>.
      </template>
    </span>
  </div>
</template>
